<template>
  <div class="authentication">
    <div class="hint-img">
      <span class="iconfont iconshenfenyanzheng"></span>
      <p>为了您的资金安全，请完成手机验证</p>
    </div>
    <div class="cont">
      <!-- 输入手机号，调起手机号键盘 --> 
      <field v-model="phone" type="tel" label="手机号" placeholder="请输入手机号" :disabled="true"/>
      <field
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <Button slot="button" size="small" type="primary" @click="getCode">{{code_text}}</Button>
      </field>
      <Button plain type="info" hairline block class="marginTop" @click="goCheckPhone">立即验证</Button>
    </div>
  </div>
</template>

<script>
import { Field, Button } from 'vant';
import { checkMobile } from "@/utils/validate";
import CreditShop from "@/api/creditshop";
import wx from "weixin-js-sdk";
import User from "@/api/user";
import { goWxConfigJsApi, wxPayTwo } from "@/utils/wxpay";
import { aliPay } from "@/utils/alipay";
export default {
  name: "authentication",
  components: {
    Field,
    Button
  },
  data() {
    return {
      phone: "",
      sms: "",
      check_code: "666666",
      code_text: "获取验证码",
      timer: null,
      btn_status: false,
      return_url: "",
      mini_status: false
    }
  },
  async mounted() {
    if (localStorage.getItem("info_mobile")) {
      this.phone = localStorage.getItem("info_mobile");
    } else {
      this.phone = await this.getPhone();
    }
    goWxConfigJsApi("chooseWXPay");
    wx.miniProgram.getEnv((res) => {
      this.mini_status = res.miniprogram;
    })
  },
  methods: {
    // 获取手机号
    async getPhone() {
      try {
        const res = await User.getUserPhone();
        if (res.code == 200) this.phone = res.data;
        return res.data;
      } catch (error) {
        console.log(error);
      }
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.btn_status) {
        if (checkMobile(this.phone) == "手机号码输入不规范") {
          this.$toast("手机号码格式不正确");
        } else {
          if (!this.timer) {
            this.count = TIME_COUNT;
            CreditShop.send_sms().then(res => {
              // console.log(res);
              this.$toast(res.msg);
            })
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                this.code_text = this.count;
                this.btn_status = true;
              } else {
                this.btn_status = false;
                this.timer = null;
                this.code_text = "获取验证码";
              }
            }, 1000);
          }
        }
      }
    },
    // 验证手机号
    async goCheckPhone() {
      try {
        const res = await CreditShop.verify_mobile(this.sms);
        if (res.code == 200) {
          this.$toast(res.msg);
          if (this.$route.query.hasOwnProperty("cardIdx") || this.$route.query.hasOwnProperty("card_type")) {
            this.goSaveMoney();
          } else {
            this.$router.push({path:'/verify',query:{type: 'dealPassword'}})
          }
        } else {
          this.$toast(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    backCodeUrl(paysn, ordersn) {
      const user_url = `https://jjtc.58zltc.com`
      const cs_url = `https://www.58zltc.com`
      const callbackUrl = `${user_url}/time_recharge_pay?appid=${localStorage.getItem('app_id')}`+
                    `&app_token=${localStorage.getItem('app_token')}&paysn=${paysn}&ordersn=${ordersn}`;
      const url = `${cs_url}/client/wechat.pay_login/snsapi_base?callback=${encodeURIComponent(callbackUrl)}`;
      return url
    },
    async goSaveMoney() {
      try {
        if (this.sms == "") return this.$toast('请输入验证码');
        const { key_id, type, recharge_id, cardIdx, card_type, qrcode_id } = this.$route.query;
        qrcode_id ? qrcode_id: sessionStorage.getItem('qrcode_id');
        if(type == 'unionpay') {
          this.return_url = `https://jjtc.58zltc.com/vipHome?appid=${localStorage.getItem("app_id")}&`
        } else {
          this.return_url = "";
        }
        const res = await CreditShop.addWeChatMoney(recharge_id, type, "", card_type, cardIdx, key_id, this.return_url,this.isWeixin_status ? 'wx' : 'h5', qrcode_id);
        if (res.code == 200) {
          // 执行微信支付
          switch (type) {
            case 'wxpay':
              wxPayTwo(res.data);
              break;
            case 'normal_allinpay':
              if (this.isWeixin_status) {
                if (res.data.pay_page === 1) {
                  const url = this.backCodeUrl(res.data.paysn, res.data.ordersn)
                  window.location.href = url;
                } else {
                  wxPayTwo(res.data);
                }
              } else {
                await aliPay(res.data.targetOrderId);
              }
              break;
            case 'fuiou':
              if (this.isWeixin_status) {
                if (res.data.pay_page === 1) {
                  const url = this.backCodeUrl(res.data.paysn, res.data.ordersn)
                  window.location.href = url;
                } else {
                  wxPayTwo(res.data);
                }
              } else {
                await aliPay(res.data.targetOrderId);
              }
              break;
            case 'lakala':
              if (this.isWeixin_status) {
                if (res.data.pay_page === 1) {
                  const url = this.backCodeUrl(res.data.paysn, res.data.ordersn)
                  window.location.href = url;
                } else {
                  // this.$toast('调用lakala');
                  wxPayTwo(res.data);
                }
              } else {
                await aliPay(res.data.targetOrderId);
              }
              break;
            case 'alipay':
              await aliPay(res.data.targetOrderId);
              break;
            case 'cash':
              this.$toast(res.msg);
              if (this.mini_status) {
                wx.miniProgram.switchTab({ url: "/pages/index/index" })
              } else {
                this.$router.push('/vip');
              }
              break;   
            case 'single_wxpay':
              this.$router.push(`/payewm?imgUrl=${res.data.qrcode}
                &ordersn=${res.data.ordersn}&money=${res.data.money}`);
              break;   
            case 'single_alipay':
              this.$router.push(`/payewm?imgUrl=${res.data.qrcode}
                &ordersn=${res.data.ordersn}&money=${res.data.money}&payType=single_alipay`);
              break;
            case 'unionpay':
              window.location.href = res.data.url;
              break;
            case 'allinpay':
              window.location.href = res.data.url;
              break;
            case 'pos':
              this.$toast('请到收银台完成刷卡后离开');
              this.$router.push('/vip');
              break;
          }
        } else {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
        console.log(error)
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>

<style lang="scss" scoped>
.authentication {
  height: 100vh;
  background: #fff;
  
  .hint-img {
    height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    background: #fbfbfb;
    justify-content: center;

    .iconshenfenyanzheng {
      font-size: 100px;
      color: #6096DD;
    }
  }

  .cont {
    padding: 0 10px;
    .marginTop {
      margin-top: 20px;
    }
  }
}
</style>
